<template>
    <div class="admin-content">
        <h3>합격자 관리</h3>
            <div class="flex-wrap right">
                <div class="ml10">
                    <div class="btn-wrap">
                        <button class="btn-box" @click="methods.getUserPassManagementList" :disabled = state.isAccessible>조회</button>
                    </div>
                </div>
                <div>
                    <div class="sch-wrap3">
                        <div>
                            <label>공고 선택</label>
                            <div class="select-box" style="width:180px;">
                                <select v-model="state.selectedSearchRecruitment" @change="methods.getNoticeOptions()">
                                    <option v-for="item in state.dataTable.searchRecruitmentList" v-bind:value="item.Seq" v-bind:key="item" v-text="item.Title" />
                                </select>
                            </div>
                        </div>
                        <div>
                            <label>지원분야</label>
                            <div class="select-box" style="width:180px;">
                                <select v-model="state.selectedSearchPart">
                                    <option value="">전체</option>
                                    <option v-for="item in state.dataTable.searchPartList" v-bind:value="item.RCRT_DIV_CD" v-bind:key="item" v-text="item.RCRT_DIV_NM" />
                                </select>
                            </div>
                        </div>
                        <div>
                            <label>전형구분</label>
                            <div class="select-box" style="width:130px;">
                                <select v-model="state.selectedSearchStepType">
                                    <option value="">전체</option>
                                    <option v-for="item in state.dataTable.searchStepTypeList" v-bind:value="item.EXAM_DIV_CD" v-bind:key="item" v-text="item.EXAM_DIV_NM" />
                                </select>
                            </div>
                        </div>
                        <div>
                            <label>합격</label>
                            <div>
                                <RadioButton name="pass" value="Y" v-model="state.searchPassType" :disabled="state.selectedSearchStepType === ''"/>
                            </div>
                        </div>
                        <div>
                            <label>불합격</label>
                            <div>
                                <RadioButton name="fail" value="N" v-model="state.searchPassType" :disabled="state.selectedSearchStepType === ''"/>
                            </div>
                        </div>
                        <div>
                            <label>지원자명</label>
                            <div>
                                <input type="text" class="input-text mr10" style="width:120px;" v-model.trim="state.searchApplicantNm"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div class="total-box flex-wrap space-between align-center">
            <p>Total <strong>{{state.dataTable.passUserManagementList.length}}</strong></p>
            <button @click="eventHandler.selectAllOnCurrentPage" class="btn-box blue btn-sm ml-2">전체 선택/해제</button>
            <div class="flex-wrap right ml-auto">
                <div class="ml10">
                    <div class="btn-wrap">
                        <button type="button" class="btn-box gray" @click="methods.setApplicantPass()">저장</button>
                        <div class="ml10">
                            <button type="button" class="btn-box orange" @click="eventHandler.onRexpertClick()">입사지원서</button>
                        </div>
                        <div class="ml10">
                            <button type="button" class="btn-box green" @click="eventHandler.onExcelClick()">지원자 엑셀 다운로드</button>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="sch-wrap3">
                        <div>
                            <label>전형구분</label>
                            <div class="select-box">
                                <select v-model="state.changeStepType">
                                    <option v-for="item in state.dataTable.searchStepTypeList" v-bind:value="item.EXAM_DIV_CD" v-bind:key="item" v-text="item.EXAM_DIV_NM" />
                                </select>
                            </div>
                        </div>
                        <div>
                            <label for="pass">합격</label>
                            <div>
                                <RadioButton name="pass" value="Y" v-model="state.changePassType" />
                            </div>
                        </div>
                        <div>
                            <label for="fail">불합격</label>
                            <div>
                                <RadioButton name="fail" value="N" v-model="state.changePassType" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-wrap no-all-check">
            <DataTable
                class="p-datatable-sm"
                scrollable
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                columnResizeMode="fit"
                :rowHover="true"
                :resizableColumns="true"
                scrollHeight="440px"
                :paginator="true"
                :rows="100"
                :value="state.dataTable.passUserManagementList"
                v-model:selection="state.selectedApplicant"
                :select-all="false"
                @page="eventHandler.onPageChange"
                data-key="APCTP_NO">

                <template #empty>
                    데이터가 없습니다.
                </template>

                <Column selectionMode="multiple" style="flex: 0 0 40px" class="tc"/>
                <Column field="APCTP_NO" header="지원자번호" headerClass="tc"></Column>
                <Column field="EXAM_NO" header="수험번호" style="flex: 0 0 100px" headerClass="tc"></Column>
                <Column field="APCTP_KR_NM" header="성명" headerClass="tc">
                    <template #body="{ data }">
                    <div @click="eventHandler.onApplicantClick(data)" class="a-hover">
                        <a href='javascript:void(0);'>{{ data.APCTP_KR_NM }}</a>
                    </div>
                </template>
                </Column>
                <Column field="APCT_PT_NM" header="지원분야" headerClass="tc"></Column>
                <Column v-for="col of state.dataTable.column" :field="col.COLUMN_FIELD" :header="col.COLUMN_HEADER" :key="col.COLUMN_FIELD" headerClass="tc">
                    <template #body = {data}>
                        <div class="select-box">
                            <select v-model="data[col.COLUMN_HEADER]" @change="eventHandler.onApplicantPassTypeChange(data, data[col.COLUMN_HEADER], col.COLUMN_VALUE)">
                                <option value="Y">Y</option>
                                <option value="N">N</option>
                                <option value=""></option>
                            </select>
                        </div> 
                    </template>
                </Column>
                <Column field="GNDR_NM" header="성별" headerClass="tc"></Column>
                <Column field="BRTH_DA" header="생년월일" headerClass="tc"></Column>
                <Column field="AGE" header="연령" headerClass="tc"></Column>
                <Column field="PHONE_NO" header="전화번호" headerClass="tc"></Column>
                <Column field="SCHL_DGR_NM" header="최종학위" headerClass="tc"></Column>
                <Column field="GRADU_DIV_NM" header="졸업" headerClass="tc"></Column>
                <Column field="SCHL_DIV_NM" header="학교명" headerClass="tc"></Column>
                <Column field="SCHL_ARA" header="지역" headerClass="tc"></Column>
                <Column field="SCHL_MAJR_NM" header="전공" headerClass="tc"></Column>
                <Column field="SCHL_SCOR" header="학점" headerClass="tc"></Column>
                <Column field="CARRER_DA" header="경력" headerClass="tc"></Column>
                <Column field="CURNT_SAL_AMT" header="현재연봉" headerClass="tc"></Column>
                <Column field="HOPE_SAL_AMT" header="희망연봉" headerClass="tc"></Column>
                <Column field="FRGN_SCOR" header="토익" headerClass="tc"></Column>
                <Column field="AMY_PFRM_NM" header="병역" headerClass="tc"></Column>
                <Column field="HANDC_NM" header="장애" headerClass="tc"></Column>
                <Column field="EXAM_DT" header="최종접수일시" style="flex: 0 0 160px" headerClass="tc"></Column>
            </DataTable>
        </div>
        <div id='applicantList' v-show="false">
            <table>
                <thead>
                    <tr>
                        <th>지원자번호</th>
                        <th>수험번호</th>
                        <th>성명</th>
                        <th>지원분야</th>
                        <th v-for="col of state.dataTable.column" :key="col.COLUMN_FIELD">
                            {{col.COLUMN_HEADER}}
                        </th>
                        <th>성별</th>
                        <th>생년월일</th>
                        <th>연령</th>
                        <th>전화번호</th>
                        <th>최종학위</th>
                        <th>졸업</th>
                        <th>학교명</th>
                        <th>지역</th>
                        <th>전공</th>
                        <th>학점</th>
                        <th>경력</th>
                        <th>현재연봉</th>
                        <th>희망연봉</th>
                        <th>토익</th>
                        <th>병역</th>
                        <th>장애</th>
                        <th>접수일</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in state.dataTable.passUserManagementList" v-bind:key="item">
                        <td>{{ item.APCTP_NO }}</td>
                        <td>{{ item.EXAM_NO }}</td>
                        <td>{{ item.APCTP_KR_NM }}</td>
                        <td>{{ item.APCT_PT_NM }}</td>
                        <td v-for="col of state.dataTable.column" :key="col.COLUMN_FIELD">
                            {{item[col.COLUMN_HEADER]}}
                        </td>
                        <td>{{ item.GNDR_NM }}</td>
                        <td>{{ item.BRTH_DA }}</td>
                        <td>{{ item.AGE }}</td>
                        <td>{{ item.PHONE_NO }} </td>
                        <td>{{ item.SCHL_DGR_NM }}</td>
                        <td>{{ item.GRADU_DIV_NM }}</td>
                        <td>{{ item.SCHL_DIV_NM }}</td>
                        <td>{{ item.SCHL_ARA }}</td>
                        <td>{{ item.SCHL_MAJR_NM }}</td>
                        <td>{{ item.SCHL_SCOR }}</td>
                        <td>{{ item.CARRER_DA }}</td>
                        <td>{{ item.CURNT_SAL_AMT }}</td>
                        <td>{{ item.HOPE_SAL_AMT }}</td>
                        <td>{{ item.FRGN_SCOR }}</td>
                        <td>{{ item.AMY_PFRM_NM }}</td>
                        <td>{{ item.HANDC_NM }}</td>
                        <td>{{ item.APCT_DA }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <Dialog
            :header="state.dialogPreview.header"
            :visible="state.dialogPreview.isOpen"
            :modal="true"
            @show="eventHandler.dialogPreview.show"
            @hide="eventHandler.dialogPreview.hide"
            @close="state.dialogPreview.isOpen = false"
            @update:visible="state.dialogPreview.isOpen = false">

            <div style="width: 1500px;">
                <RECRC0310D :Seq="state.applicant.Seq" :FORM_NO="state.applicant.FORM_NO" :APCT_SEQ="state.applicant.APCT_SEQ"></RECRC0310D>
            </div>
        </Dialog>
    </div>
</template>
<script>
import { reactive } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { recruitmentPassManagement } from '@/modules/recruit/REC_RC0400R.js';
import { applicantMng } from '@/modules/recruit/REC_RC0300R.js';
import { useStore } from 'vuex';
import alertManager from '@/utils/alert-confirm-manager';
import RECRC0310D from '@/router/views/recruit/REC_RC0310D.vue';
import { utils, writeFileXLSX } from 'xlsx';

export default {
    components: { RECRC0310D },
    setup() {
        // 스토어
        const store = useStore();

        // 로그인된 아이디 정보
        const _userInfo = JSON.parse(sessionStorage.getItem('UserInfo'));
        
        // state
        const state = reactive({
            // 데이터 테이블 state
            dataTable: {
                // 공고 목록
                searchRecruitmentList: new Array(),
                // 지원분야 목록
                searchPartList: new Array(),
                // 전형구분 목록
                searchStepTypeList: new Array(),
                // 합격자 관리 목록
                passUserManagementList: new Array(),
                // 전형절차 컬럼 목록
                column: new Array(),
            },

            // 선택된 공고(검색)
            selectedSearchRecruitment: '',
            // 지원자명(검색)
            searchApplicantNm: '',
            // 선택한 지원분야(검색)
            selectedSearchPart: '',
            // 선택한 전형구분(검색)
            selectedSearchStepType: '',
            // 선택한 전형구분 합불타입
            searchPassType: 'Y',
            // 변경할 전형구분
            changeStepType: '',
            // 변경할 전형구분 합불타입
            changePassType: 'Y',
            // 선택한 지원서
            selectedApplicant: new Array(),
            // 변경할 지원서
            changeApplicant: new Array(),

            // 지원자 상세보기 팝업
            dialogPreview: {
                header: "입사지원서 상세보기",
                // 팝업 오픈 여부
                isOpen: false
            },

            // 지원자 정보
            applicant:{
                Seq: '',
                FORM_NO: 0,
                APCT_SEQ: 0,
            },

            // 선택된 공고의 지원자 공고 목록 조회 파라미터
            parameter: {
                Seq: 0,
                boardCategory: 'RECCM001',
                userInfo: _userInfo.EmplID,
                APCT_PT_CD: '',
                APCTP_KR_NM: '',
                MEMO_REG_YN: 'N'
            },

            // 조회 버튼 제어 상태 // 초기값 true 조회 불가
            isAccessible: true,

            // 현재 체크박스 데이터
            currentPageData: new Array(),
            
            // 전체 선택/해제
            selectAllState: false,
        });

        // 메서드
        const methods = {

            // 채용 공고 조회 메서드 (1)
            getNotice: async () => {

                try {
                    // 채용공고 조회
                    const result = await recruitmentPassManagement.getNotice('RECCM001');

                    // 공고 목록 바인딩
                    state.dataTable.searchRecruitmentList = result.Table.rows;

                    // 선택된 공고가 없을 시 최상위 공고목록 설정
                    if (!state.selectedSearchRecruitment && result.Table.rows.length > 0) state.selectedSearchRecruitment = result.Table.rows[0]['Seq'];

                    // 지원 분야 조회
                    if (result.Table.rows.length > 0) {
                        await methods.getNoticeOptions();
                    }
                }
                catch (error) {

                    console.error("채용 공고 조회 Error:", error);
                }
            },

            // 지원 분야 조회 메서드 (2)
            getNoticeOptions: async () => {

                try {
                    // 모집지원분야 / 전형절차 항목 조회
                    const result = await recruitmentPassManagement.getNoticeOptions(`${state.selectedSearchRecruitment}`);

                    // 선택한 지원분야(검색) 초기화
                    state.selectedSearchPart = ''
                    
                    // 지원분야 목록 초기화
                    state.dataTable.searchPartList = new Array()

                    // 지원분야 목록 설정
                    const partList = result.Table.rows.filter(row => row.USE_YN === 'Y')
                    state.dataTable.searchPartList = partList

                    // 전형구분 목록 설정
                    const typeList = result.Table1.rows.filter(row => row.USE_YN === 'Y')
                    state.dataTable.searchStepTypeList = typeList

                    // 전형 구분 목록 존재 시
                    if (state.dataTable.searchStepTypeList.length) {
                        
                        // 변경할 전형 구분 설정
                        state.changeStepType = typeList[0]["EXAM_DIV_CD"]
                    }

                    // 합격자 관리 목록 조회
                    methods.getUserPassManagementList();
                }
                catch (error) {

                    console.error("지원 분야 조회 Error:", error);
                }
            },

            // 합격자 관리 목록 조회 메서드 (3)
            getUserPassManagementList: () => {

                // 데이터 조회 처리중일 시 return
                if (store.getters.isLoading === true) return;

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                // 지원자선택 초기화
                state.selectedApplicant = new Array();
                state.changeApplicant = new Array();
                state.selectAllState = false;

                // 현재공고 Seq 설정
                state.parameter.Seq = state.selectedSearchRecruitment;
                
                // 합격자 관리 매개변수 설정
                const params = {
                    'Seq': `${state.selectedSearchRecruitment}`,
                    'APCTP_KR_NM': state.searchApplicantNm,
                    'APCT_PT_CD': state.selectedSearchPart,
                    'EXAM_DIV_CD': state.selectedSearchStepType,
                    'PASS_YN': state.selectedSearchStepType === '' ? '' : state.searchPassType
                }

                // 합격자 관리 목록 조회
                recruitmentPassManagement.getUserPassManagementList(JSON.stringify(params)).then(result => {

                    
                    // 합격자 관리 목록 설정
                    if (result.Table.columns[0].name !== 'COLUMN_FIELD') {

                        state.dataTable.passUserManagementList = result.Table.rows;
                        state.dataTable.column = result.Table1.rows;
                        
                        // 전화번호 정규식 적용
                        result.Table.rows.forEach(row => { row.PHONE_NO = row.PHONE_NO.replace(/^(\d{3})(\d{4})(\d{4})$/, "$1-$2-$3") })

                        // 현재 페이지 전체 데이터
                        state.currentPageData = result.Table.rows.slice(0, 100);
                    }
                    else {

                        state.dataTable.passUserManagementList = new Array();
                        state.dataTable.column = new Array();
                    }

                    // 선택된 공고의 지원자 목록 조회
                    applicantMng.getApplicant(JSON.stringify(state.parameter)).then(result => {

                        // result
                        const applicantInfo = result.Table.rows;

                        // 지원자 양식번호 설정
                        if (applicantInfo.length) state.applicant.FORM_NO = applicantInfo[0].FORM_NO;

                    }).finally(() => {

                        // 조회 버튼 활성화
                        state.isAccessible = false

                        // 스토어 값 변경
                        store.commit("setLoadingStatus", false);
                    })
                })
            },

            // 합불 저장
            setApplicantPass: () => {

                // 지원자 선택 및 변경 여부
                const isApplicant = state.selectedApplicant.length === 0 && state.changeApplicant.length === 0

                if (isApplicant) {

                    alertManager.alert.warning('선택된 지원자가 없습니다.')
                    return
                }

                // 스토어 값 변경
                //store.commit("setLoadingStatus", true);

                let applicant = ''

                // 선택한 지원서 여부
                if (state.selectedApplicant.length > 0) {

                    let applicantList = new Array()

                    state.selectedApplicant.forEach(data =>

                        applicantList.push({
                            'APCT_SEQ': data.APCT_SEQ,
                            'Seq': state.selectedSearchRecruitment,
                            'EXAM_DIV_CD' : state.changeStepType,
                            'PASS_YN': state.changePassType,
                        })
                    )

                    applicant = JSON.stringify(applicantList)
                }
                else{

                    applicant = JSON.stringify(state.changeApplicant)
                }

                // 프로젝트 정보 저장
                recruitmentPassManagement.setApplicantPass(applicant).then((response) => {

                    if(response.success) {
                        
                        alertManager.alert.success('저장 되었습니다.')

                        // 목록 재조회
                        methods.getUserPassManagementList();
                    }
                    
                }).finally(() =>{

                    // 스토어 값 변경
                    //store.commit("setLoadingStatus", false);
                    
                });
            }
        }

        // 이벤트 핸들러
        const eventHandler = {

            // 지원자 클릭
            onApplicantClick:(data) => {

                state.applicant.Seq = state.selectedSearchRecruitment;
                state.applicant.APCT_SEQ = data.APCT_SEQ;
                state.dialogPreview.isOpen = true;
            },

            // 합불 변경 이벤트
            onApplicantPassTypeChange: (info, passYN, examDivCd) => {
                
                let changeApplicant = {

                    'APCT_SEQ': info.APCT_SEQ,
                    'Seq': state.selectedSearchRecruitment,
                    'EXAM_DIV_CD' : examDivCd,
                    'PASS_YN': passYN,
                }

                state.changeApplicant.push(changeApplicant)
            },

            // 입사지원서 클릭 이벤트 (Rexpert 뷰어 오픈)
            onRexpertClick: () => {

                if (state.selectedApplicant.length === 0 && state.changeApplicant.length === 0) {

                    alertManager.alert.warning('선택된 지원자가 없습니다.');
                    return
                }

                // 파라미터 넘기기
                let apct_seq = '';
                let seq, div_cd;

                state.selectedApplicant.forEach(data => {

                    apct_seq += `${data.APCT_SEQ},`,
                    seq = state.selectedSearchRecruitment
                })

                apct_seq = apct_seq.slice(0, -1);

                state.dataTable.searchRecruitmentList.forEach(data => {

                    if(data.Seq === state.selectedSearchRecruitment){
                        
                        //CD021001 신입 CD021002 경력
                        div_cd = data.CARR_DIV_CD !== 'CD021001' ? '경력.crf' : '신입.crf';
                    }
                })

                // 결과 페이지 이동 경로 설정
                switch (location.hostname) {

                    // 개발자 로컬
                    case 'localhost':
                        window.open('http://localhost/Koreit.Web.API/ClipReport4/report4.aspx?div_cd='+div_cd+'&APCT_SEQ='+apct_seq+'&SEQ='+seq+'&PAGE=400');
                        break;
                    // 미니소프트 개발 (나중에 주소 수정할것)
                    case 'admin-koreit.minisoft.co.kr':
                        window.open('https://webapi-koreit.minisoft.co.kr/ClipReport4/report4.aspx?div_cd='+div_cd+'&APCT_SEQ='+apct_seq+'&SEQ='+seq+'&PAGE=400');
                        break;
                    // 한토신 신개발
                    case 'devadmin.koreit.co.kr':
                        window.open('http://devwebapi.koreit.co.kr/ClipReport4/report4.aspx?div_cd='+div_cd+'&APCT_SEQ='+apct_seq+'&SEQ='+seq+'&PAGE=400');
                        break;
                    // 한토신 신운영
                    case 'admin.koreit.co.kr':
                        window.open('https://webapi.koreit.co.kr/ClipReport4/report4.aspx?div_cd='+div_cd+'&APCT_SEQ='+apct_seq+'&SEQ='+seq+'&PAGE=400');
                        break;
                    // 미니소프트 개발
                    default:
                        window.open('https://webapi-koreit.minisoft.co.kr/ClipReport4/report4.aspx?div_cd='+div_cd+'&APCT_SEQ='+apct_seq+'&SEQ='+seq+'&PAGE=400');
                        break;
                }
            },

            // 지원자 엑셀 다운로드
            onExcelClick: () => {

                let config = { raw: true, type: 'string' }

                // table id 설정
                var excelData = utils.table_to_sheet(document.getElementById('applicantList'), config)

                // 컬럼 길이 및 갯수 설정
                var wscols = [
                    {wch: 30}, {wch: 30}, {wch: 30}, {wch: 30}, {wch: 30},
                    {wch: 30}, {wch: 30}, {wch: 30}, {wch: 30}, {wch: 30},
                    {wch: 30}, {wch: 30}, {wch: 30}, {wch: 30}, {wch: 30},
                    {wch: 30}, {wch: 30}, {wch: 30}, {wch: 30}, {wch: 30},
                    {wch: 30},
                ]

                excelData['!cols'] = wscols
                var workBook = utils.book_new() // 새 시트 생성 
                utils.book_append_sheet(workBook, excelData, '지원자 목록') // 시트 명명, 데이터 지정
                writeFileXLSX(workBook, '지원자 목록.xlsx') // 엑셀파일 만듬
            },

            // TODO: 정리 필요
            dialogPreview: {

                show: () => {
                    
                },

                hide: () => {

                }
            },

            //페이지 변경시 체크박스 초기화
            onPageChange: (event) => {
                
                state.selectedApplicant = new Array();
                state.changeApplicant = new Array();
                state.selectAllState = false;
                const currentPageNumber = event.page + 1; // 현재 페이지 (번호 2일때 1)
                
                const startIndex = (currentPageNumber - 1) * 100;
                const endIndex = startIndex + 100;
                state.currentPageData = state.dataTable.passUserManagementList.slice(startIndex, endIndex);
            }

            //현재페이지 체크박스 전체 선택
            , selectAllOnCurrentPage: () => {

                if (state.selectAllState) {// 전체해제
                    state.selectedApplicant = new Array();
                } 
                else { // 전체선택
                    state.selectedApplicant = state.currentPageData;
                }

                state.selectAllState = !state.selectAllState;
            }
        }

        // Dom 객체 그려진 이후 hook
        onMounted(() => {

            // 조회 버튼 비활성화 // 지원분야 조회된 이후 목록 조회 가능
            state.isAccessible = true;

            // 합격자 관리 목록 초기조회
            methods.getNotice();
        });

        return {
            state,
            recruitmentPassManagement,
            methods,
            eventHandler,
            utils,
            // writeFileXLSX,
            RECRC0310D,
        }
    }
}
</script>