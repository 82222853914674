import { FoxBizRequest, FoxRestBizClient } from "@/api/client";

class ApplicantMng {

    /**
     * 생성자
     */
    constructor() {
        
    }

    // 채용공고 목록 조회
    getNotice(boardCategory, userInfo) {

        return new Promise((resolve, reject) => {
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.RecruitBiz';
            request.methodId = 'GetEvalNotice';
            request.parameters.boardCategory = boardCategory;
            request.parameters.userInfo = userInfo;

            service.execute(request).then(response => {

                const tables = response.data.result.value;

                if(!!tables) resolve(tables);
                else resolve(null);
            }).catch(error => reject(error));
        })
    }

    // 공고별, 평가자별 지원분야 조회
    getPart(seq, userInfo) {

        return new Promise((resolve, reject) => {
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.RecruitBiz';
            request.methodId = 'GetEvalPart';
            request.parameters.seq = seq;
            request.parameters.userInfo = userInfo;

            service.execute(request).then(response => {

                const tables = response.data.result.value;

                if(!!tables) resolve(tables);
                else resolve(null);
            }).catch(error => reject(error));
        })
    }

    // 선택된 공고의 지원자 목록 조회
    getApplicant(parameters) {

        return new Promise((resolve, reject) => {
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.RecruitBiz';
            request.methodId = 'GetApplicantList';
            request.parameters.dic = parameters;

            service.execute(request).then(response => {

                const tables = response.data.result.value;

                if(!!tables) resolve(tables);
                else resolve(null);
            }).catch(error => reject(error));
        })
    }

    // 지원자별, 평가자별 메모 조회
    getEvalMemo(applicant) {

        return new Promise((resolve, reject) => {
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.RecruitBiz';
            request.methodId = 'GetEvalMemo';
            request.parameters.applicant = applicant;

            service.execute(request).then(response => {

                const tables = response.data.result.value;

                if(!!tables) resolve(tables);
                else resolve(null);
            }).catch(error => reject(error));
        })
    }

    // 평가자 메모 등록
    saveEvalMemo(memoData) {

        return new Promise((resolve, reject) => {
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.RecruitBiz';
            request.methodId = 'SaveEvalMemo';
            request.parameters.dic = memoData;

            service.execute(request).then(response => {

                resolve(response)
            }).catch(error => reject(error));
        })
    }

    // 평가자 메모 삭제
    deleteEvalMemo(applicant) {

        return new Promise((resolve, reject) => {
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.RecruitBiz';
            request.methodId = 'DeleteEvalMemo';
            request.parameters.applicant = applicant;

            service.execute(request).then(response => {

                resolve(response)
            }).catch(error => reject(error));
        })
    }

}

export const applicantMng = new ApplicantMng();